import React, {useState} from "react"
import { Switch } from '@headlessui/react'
import {useMutation} from "@apollo/client";
import {navigate} from "gatsby";
import {UPDATE_TENANT_CLICKANDMEET_MUTATION} from "../../graphql/clickAndMeetGraphQL";
import {useTranslation} from "react-i18next";

import logo from "../../images/logo.png";
import dancingVideo from "../../videos/dancing.mp4";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function removeItemAll(arr, value) {
    let i = 0;
    while (i < arr.length) {
        if (arr[i] === value) {
            arr.splice(i, 1);
        } else {
            ++i;
        }
    }
    return arr;
}

export default function Setup(prop) {

    const { t } = useTranslation()

    const [
        updateTenant,
        {loading: mutationLoading, error: mutationError},
    ] = useMutation(UPDATE_TENANT_CLICKANDMEET_MUTATION)

    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [firstSlot, setFirstSlot] = useState("09:00")
    const [lastSlot, setLastSlot] = useState("16:30")
    const [slots, setSlots] = useState("30")
    const [interval, setInterval] = useState("30")

    const [monday, setMonday] = useState(false)
    const [tuesday, setTuesday] = useState(false)
    const [wednesday, setWednesday] = useState(false)
    const [thursday, setThursday] = useState(false)
    const [friday, setFriday] = useState(false)
    const [saturday, setSaturday] = useState(false)
    const [sunday, setSunday] = useState(false)

    const [workdays, setWorkdays] = useState([])

    const handleSetup = async (event) => {
        // Block native form submission.
        event.preventDefault()

        updateTenant({
            variables: {
                tenantId: prop.location.state.tenant.id,
                title: title,
                description: description,
                firstSlot: firstSlot,
                lastSlot: lastSlot,
                slots: Number(slots),
                interval: interval,
                workdays: workdays
            },
        }).then((res) => {
            navigate("/meet/services", {
                state: { tenant: res.data.updateTenant.tenant },
            })
        })
    }

    if (mutationLoading) {
        console.log(mutationLoading)
    }

    if (mutationError != undefined) {
        console.log(mutationError)
    }

    return (
        <div className="min-h-screen bg-white flex">
            <div className="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div>
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="ClickAnd"
                        />
                        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{t('meet.setup.title')}</h2>
                        <p className="mt-2 text-sm text-gray-600">
                            {t('meet.setup.subtitle')}
                        </p>
                    </div>

                    <div className="mt-8">

                        <div className="mt-6">
                            <form
                                onSubmit={handleSetup}
                                className="space-y-6">
                                <div>
                                    <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                                        {t('input.widget.meet.title')}
                                    </label>
                                    <div className="mt-1">
                                        <input
                                            id="title"
                                            name="title"
                                            type="text"
                                            autoComplete="title"
                                            required
                                            placeholder={t('input.widget.meet.title.placeholder')}
                                            onChange={(e) => {
                                                setTitle(e.target.value)
                                            }}
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                                        {t('input.widget.meet.description')}
                                    </label>
                                    <div className="mt-1">
                                        <textarea
                                            id="description"
                                            name="description"
                                            rows={3}
                                            placeholder={t('input.widget.meet.description.placeholder')}
                                            onChange={(e) => {
                                                setDescription(e.target.value)
                                            }}
                                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            defaultValue={''}
                                        />
                                    </div>
                                </div>
                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label htmlFor="firstSlot" className="block text-sm font-medium text-gray-700">
                                            {t('input.widget.meet.first.slot')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="firstSlot"
                                                name="firstSlot"
                                                type="text"
                                                autoComplete="firstSlot"
                                                required
                                                placeholder={t('input.widget.meet.first.slot.placeholder')}
                                                onChange={(e) => {
                                                    setFirstSlot(e.target.value)
                                                }}
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="lastSlot" className="block text-sm font-medium text-gray-700">
                                            {t('input.widget.meet.last.slot')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="lastSlot"
                                                name="lastSlot"
                                                type="text"
                                                autoComplete="lastSlot"
                                                required
                                                placeholder={t('input.widget.meet.last.slot.placeholder')}
                                                onChange={(e) => {
                                                    setLastSlot(e.target.value)
                                                }}
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-6">
                                    <div>
                                        <label htmlFor="interval" className="block text-sm font-medium text-gray-700">
                                            {t('input.widget.meet.interval')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="interval"
                                                name="interval"
                                                type="number"
                                                autoComplete="interval"
                                                required
                                                placeholder={t('input.widget.meet.interval.placeholder')}
                                                onChange={(e) => {
                                                    setInterval(e.target.value)
                                                }}
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <label htmlFor="slots" className="block text-sm font-medium text-gray-700">
                                            {t('input.widget.meet.visible.slots')}
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                id="slots"
                                                name="slots"
                                                type="number"
                                                autoComplete="slots"
                                                required
                                                placeholder={t('input.widget.meet.visible.slots.placeholder')}
                                                onChange={(e) => {
                                                    setSlots(e.target.value)
                                                }}
                                                className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <label htmlFor="slots" className="block text-sm font-medium text-gray-700">
                                        {t('input.widget.meet.opening.days')}
                                    </label>

                                    <div className="mt-1 grid grid-cols-3">
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={monday}
                                                onChange={(e) => {
                                                    setMonday(e)
                                                    if (e) {
                                                        workdays.push("Monday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Monday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    monday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">
                                                    {t('label.monday')}
                                                </span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        monday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">
                                                    {t('label.monday')}
                                                </span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={tuesday}
                                                onChange={(e) => {
                                                    setTuesday(e)
                                                    if (e) {
                                                        workdays.push("Tuesday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Tuesday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    tuesday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">
                                                    {t('label.tuesday')}
                                                </span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        tuesday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">
                                                    {t('label.tuesday')}
                                                </span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={wednesday}
                                                onChange={(e) => {
                                                    setWednesday(e)
                                                    if (e) {
                                                        workdays.push("Wednesday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Wednesday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    wednesday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">

                                                    {t('label.wednesday')}
                                                </span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        wednesday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">
                                                    {t('label.wednesday')}
                                                </span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={thursday}
                                                onChange={(e) => {
                                                    setThursday(e)
                                                    if (e) {
                                                        workdays.push("Thursday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Thursday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    thursday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">{t('label.thursday')}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        thursday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">
                                                    {t('label.thursday')}
                                                </span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={friday}
                                                onChange={(e) => {
                                                    setFriday(e)
                                                    if (e) {
                                                        workdays.push("Friday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Friday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    friday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">{t('label.friday')}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        friday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">{t('label.friday')}</span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={saturday}
                                                onChange={(e) => {
                                                    setSaturday(e)
                                                    if (e) {
                                                        workdays.push("Saturday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Saturday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    saturday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">{t('label.saturday')}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        saturday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">{t('label.saturday')}</span>
                                            </Switch.Label>
                                        </Switch.Group>
                                        <Switch.Group as="div" className="mt-2 flex items-center">
                                            <Switch
                                                checked={sunday}
                                                onChange={(e) => {
                                                    setSunday(e)
                                                    if (e) {
                                                        workdays.push("Sunday")
                                                        setWorkdays(workdays)
                                                    } else {
                                                        let wd = removeItemAll(workdays, "Sunday")
                                                        setWorkdays(wd)
                                                    }

                                                }}
                                                className={classNames(
                                                    sunday ? 'bg-indigo-600' : 'bg-gray-200',
                                                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                                )}
                                            >
                                                <span className="sr-only">{t('label.sunday')}</span>
                                                <span
                                                    aria-hidden="true"
                                                    className={classNames(
                                                        sunday ? 'translate-x-5' : 'translate-x-0',
                                                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                                    )}
                                                />
                                            </Switch>
                                            <Switch.Label as="span" className="ml-3">
                                                <span className="text-xs font-medium text-gray-900">{t('label.sunday')}</span>
                                            </Switch.Label>
                                        </Switch.Group>
                                    </div>
                                </div>

                                <div>
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        {t('meet.setup.button')}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block relative w-0 flex-1">
                <video
                    className="absolute inset-0 h-full w-full object-cover"
                    autoPlay
                    loop
                    playsInline
                    muted>
                    <source
                        src={dancingVideo}
                        type="video/mp4" />
                </video>
            </div>
        </div>
    )
}
